<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#68D389  "
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-app id="inspire">
      <v-layout wrap justify-center class="login_page">
        <v-flex xs12 sm6 md4 lg3 px-2 align-self-center>
          <v-layout wrap justify-center>
            <v-flex xs112 xl10>
              <v-snackbar
                v-model="showSnackBar"
                color="#68D389"
                right
                :timeout="timeout"
              >
                <v-layout wrap justify-center>
                  <v-flex text-left class="align-self-center">
                    <span style="color: #000">
                      {{ msg }}
                    </span>
                  </v-flex>
                  <v-flex text-right>
                    <v-btn
                      small
                      :ripple="false"
                      text
                      @click="showSnackBar = false"
                    >
                      <v-icon style="color: #000">mdi-close</v-icon>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-snackbar>
              <v-card tile flat>
                <v-layout wrap justify-center>
                  <v-flex px-8 py-6>
                    <v-form ref="form" @submit.prevent="validateInput">
                      <v-layout wrap justify-center>
                        <v-flex xs12>
                          <span
                            style="
                              font-family: poppinsmedium;
                              font-size: 15px;
                              color: #b8b8b8;
                            "
                          >
                            Welcome to
                          </span>
                        </v-flex>
                        <v-flex xs12 py-4 style="cursor: pointer">
                          <router-link to="/">
                            <span
                              style="
                                font-family: poppinsbold;
                                font-size: 20px;
                                cursor: pointer;
                                color: #545454;
                              "
                            >
                            Mudumalai Tiger Reserve
                            </span>
                          </router-link>
                        </v-flex>
                        <v-flex xs12>
                          <v-layout wrap justify-center>
                            <v-flex xs6 lg4 px-2>
                              <span
                                style="
                                  font-family: poppinssemibold;
                                  font-size: 15px;
                                  color: #000;
                                "
                              >
                                Log In
                              </span>
                              <v-progress-linear
                                height="2"
                                value="100"
                                color="#68D389"
                              ></v-progress-linear>
                            </v-flex>
                            <!-- <v-flex xs6 lg4 px-2>
                            <router-link to="/SignUp">
                              <span
                                style="
                                  font-family: poppinssemibold;
                                  font-size: 15px;
                                  color: #000;
                                "
                              >
                                Sign up
                              </span>
                            </router-link>
                          </v-flex> -->
                          </v-layout>
                        </v-flex>
                        <v-flex pt-8 xs12 text-left>
                          <v-layout wrap justify-center>
                            <v-flex xs12 pb-2>
                              <span
                                style="
                                  font-family: ;
                                  font-size: 12px;
                                  color: #ababab;
                                "
                              >
                                Phone
                              </span>
                            </v-flex>
                            <v-flex xs12>
                              <v-form @submit.prevent="validateInput">
                                <v-text-field
                                  type="text"
                                  color="#717171"
                                  placeholder="Phone"
                                  outlined
                                  dense
                                  v-model="phoneno"
                                  hide-details
                                >
                                </v-text-field>
                              </v-form>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <!-- <v-flex pt-2 xs12 text-left>
                          <router-link to="/ForgotPassword">
                            <span
                              style="
                                font-family: poppinssemibold;
                                font-size: 12px;
                                text-transform: none;
                              "
                            >
                              Forgot Password ?
                            </span>
                          </router-link>
                        </v-flex> -->
                        <v-flex xs12 py-6>
                          <v-btn
                            block
                            tile
                            color="#68D389"
                            light
                            :ripple="false"
                            depressed
                            @click="validateInput"
                            class="itemValue"
                          >
                            Continue
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-form>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-app>
  </div>
  
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      phoneno: null,
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      msg2: null,
      rules: {
        required: (value) => !!value || "Required.",
        min: (value) => value.length <= 10 || "Max 10 characters",
      },
    };
  },

  methods: {
    validate() {
      this.$refs.form.validate();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    validateInput() {
      if (!this.phoneno) {
        this.msg = "Please enter your number";
        this.showSnackBar = true;
        return;
      } else if (this.phoneno.length != 10) {
        this.msg = "Please enter valid number";
        this.showSnackBar = true;
      } else this.login();
    },
    login() {
      this.appLoading = true;
      var data = {};
      data["phone"] = this.phoneno;
      data["role"] = "alladmin";
      if (this.$refs.form.validate()) {
        axios({
          method: "GET",
          url: "/user/signup/signin/send/otp",
          params: data,
        })
          .then((response) => {
            this.appLoading = false;
            if (response.data.status) {
              this.$emit("stepper", {
                phoneno: this.phoneno,
                response: response.data,
                winStep: 1,
              });
            } else {
              this.msg = "Unauthorised access";
              this.showSnackBar = true;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            // this.ServerError = true;
            console.log(err);
          });
      }
    },
  },
};
</script>
<style>
.centered-input input {
  text-align: center;
}
</style>